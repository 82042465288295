import React, { FormEvent } from "react";
import "./MailChimpForm.css";
import { ReactComponent as Sucess } from "../../static/imgs/icons/sucess.svg";

const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
export default class MailChimpForm extends React.Component {
  state: {
    emailSubmited: boolean,
    errors: Array<string>
  };

  constructor(props: any) {
    super(props);

    this.state = {
      emailSubmited: false,
      errors: []
    }
  }

  handleSubmit (event : React.FormEvent) {
    
    let errors : Array<string> = [];
    let email = (document.getElementById("mce-EMAIL") as HTMLInputElement).value;
    let name = (document.getElementById("mce-FNAME") as HTMLInputElement).value;
    
    if ((name.length < 3) || name === "") { 
      errors.push("Name");
    }
    
    if (!validEmailRegex.test(email)) {
      errors.push("valid Email");
    }
    
    if (errors.length > 0) {
      event.preventDefault();
      this.setState({ errors: errors });
    } else {
      setTimeout(() => {
        this.setState({ errors: errors, emailSubmited: true });
      }, 0);
    }
  }

  handleErrors () {
    let { errors } = this.state;
    if (errors.length > 0) {
      return (
        <div className="errors">Please enter a {errors.toString().replace(",", " and ")}</div>
      )
    } else {
      return null;
    }
  }

  renderForm () {
    return (
      <form 
        action="https://festfrwrd.us20.list-manage.com/subscribe/post?u=f409be16f9b75656d9da855e9&amp;id=1712feaf33" 
        method="post" 
        id="mc-embedded-subscribe-form subscribe" 
        name="mc-embedded-subscribe-form" 
        className="validate" 
        target="_blank" 
        onSubmit={this.handleSubmit.bind(this)}
      >
        <div id="mc_embed_signup_scroll">
          <div className="message">We’ll make sure you get the latest updates without all the spam. Short and sweet.</div>
          <div className="mc-field-group">
            <label htmlFor="mce-FNAME" style={{ display: 'none'}}>First Name</label>
            <input type="text" name="FNAME" className="" id="mce-FNAME" placeholder="Your Name" />
            {this.handleErrors()}
          </div>
          <div className="mc-field-group">
            <label htmlFor="mce-EMAIL" style={{ display: 'none'}}>Email Address</label>
            <input type="email" name="EMAIL" id="mce-EMAIL" placeholder="Your Email" />
          </div>
          <div id="mce-responses" className="clear">
            <div className="response" id="mce-error-response" style={{ display: 'none'}}></div>
            <div className="response" id="mce-success-response" style={{ display: 'none'}}></div>
          </div>
          {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
          <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true"><input type="text" name="b_dc2f5ce415cb97af87ec2c74f_0264e004f4" tabIndex={-1} /></div>
          <div className="clear"><input type="submit" value="Subscribe!" name="subscribe" id="mc-embedded-subscribe" className="button" /></div>
        </div>
      </form>
    )
  }

  renderSuccess () {
    return (
      <div className="success-message">
        <h4><Sucess /> You’re all set! We’ll be in touch soon.</h4>
      </div>
    )
  }

  public render() {
    let { emailSubmited } = this.state;
    return (
      <div id="mail-chimp-container">
        <h2>Get the LTST NWS</h2>
        {emailSubmited ? this.renderSuccess() : this.renderForm() }
      </div>
    );
  }
};