import * as React from "react";
import "./VideoCarousel.css";
import { CSSTransitionGroup } from "react-transition-group";
import { slide } from "../../../state/types";
import { ReactComponent as Arrow } from "../../../static/imgs/arrows/left_01.svg";
import iPhoneFrame from "../../../static/imgs/iphone_frame.png";
import iPhoneShadow from "../../../static/imgs/shadow/shadow_flat.svg";
interface VideoCarouselProps {
  title: string,
  slides: Array<slide>
}

export default class VideoCarousel extends React.Component<VideoCarouselProps> {
  private carousel: React.RefObject<HTMLDivElement>;
  private video: React.RefObject<HTMLVideoElement>;
  private overlay: React.RefObject<HTMLDivElement>;
  private videoWrapper: React.RefObject<HTMLDivElement>;
  private playing: boolean;
  private gestureSensitivty: number;

  state: {
    swipeLeft: boolean,
    selected: number,
    touchstartX: number,
    touchendX: number,
  };

  constructor(props: VideoCarouselProps) {
    super(props);

    this.state = {
      swipeLeft: true,
      selected: 0,
      touchstartX: 0,
      touchendX: 0,
    };

    this.gestureSensitivty = 75;
    this.carousel = React.createRef();
    this.video = React.createRef();
    this.overlay = React.createRef();
    this.videoWrapper = React.createRef();
    this.playing = false;
  }
  componentDidMount() {
    let vm = this;
    let carousel = this.carousel.current;

    window.addEventListener("scroll", this.onScroll.bind(this), false);

    if (carousel) {
      carousel.addEventListener("touchstart", function (event) {
        vm.setState({
          touchstartX: event.changedTouches[0].screenX,
          touchstartY: event.changedTouches[0].screenY
        })      
      });
  
      carousel.addEventListener("touchend", function (event) {
        vm.setState({
          touchendX: event.changedTouches[0].screenX,
          touchendY: event.changedTouches[0].screenY
        }, () => {
          vm.handleGesure();
        });
      }, false);
    }
  }

  detectIE () {
    var ua = window.navigator.userAgent;
  
    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }
  
    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }
  
    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  }

  onScroll () {
    if (this.carousel.current && window.scrollY >= this.carousel.current.offsetTop - (this.carousel.current.scrollHeight * 0.5)) {
      if (!this.playing) {
        this.playing = true;
        if (this.videoWrapper.current) {
          let video = this.video.current;
          this.videoWrapper.current.classList.add("entry");
          setTimeout(() => {
            if (video) {
              let videoPromise = video.play();
              
              if (videoPromise !== undefined) {
                videoPromise.then(() => {})
                .catch(err => { console.log(err) });
              }
            }
          }, 1);
        }
      }
    }
  }
  

  selectSlide (index : number) {
    this.setState({
      selected: index
    }, () => {
      let video = this.video.current;
      setTimeout(() => {
        if (video) {
          let videoPromise = video.play();
          
          if (videoPromise !== undefined) {
            videoPromise.then(() => {})
            .catch(err => { console.log(err) });
          }
        }
      }, 1);
    });
  }

  handleGesure () {
    let { slides } = this.props;
    let { touchstartX, touchendX, selected } = this.state;

    if (touchendX < touchstartX && touchstartX - touchendX > this.gestureSensitivty) {
      //left
      this.setState({ swipeLeft: true });
      this.selectSlide((((selected - 1) % slides.length) + slides.length) % slides.length);
    }
    if (touchendX > touchstartX && touchendX - touchstartX > this.gestureSensitivty) {
      //right
      this.setState({ swipeLeft: false });
      this.selectSlide((((selected + 1) % slides.length) + slides.length) % slides.length);
    }
  }
  
  renderSlides () {
    let { slides } = this.props;
    let { selected, swipeLeft } = this.state;

    if (!slides) return;

    return slides.map((slide, index) => {
      return (
        <div
          key={`slide-icon-${index}`}
          onClick={() => this.selectSlide(index)} 
          className={`slide ${selected === index ? 'slide__selected' : ''} ${swipeLeft ? 'swipe-left' : 'swipe-right'}`} 
          >
          <div>
            {slide.img}
          </div>
          <div className="slide__text">
            <h3 className="slide__title">{slide.title}</h3>
            <p className="slide__description">{slide.description}</p>
          </div>
        </div>
      );
    });
  };

  renderMobileSlide () {
    let { slides } = this.props;
    let { selected } = this.state;
    let slide = slides[selected];
    return (
      <div
        key={`slide-icon-${selected}`}
        onClick={() => this.selectSlide(selected)} 
        className="slide slide__selected"
        >
        <div className="slide__text">
          <h3 className="slide__title">{slide.title}</h3>
          <p className="slide__description">{slide.description}</p>
        </div>
      </div>
    )
  }

  renderDots () {
    let { slides } = this.props;
    let { selected } = this.state;

    return slides.map((dot, index) => {
      return (
        <div 
          onClick={() => this.selectSlide(index)}
          className={`dot ${selected === index ? 'dot__selected' : ''}`}
          key={`dot-${index}`}
        />
      )
    });
  };

  public render() {
    let { slides, title } = this.props;
    let { selected, swipeLeft } = this.state;
    return (
      <div className="video-carousel" ref={this.carousel}>
        <div className="video-carousel__info">
          <h2>{title}</h2>
          <div className="slide__wrapper desktop">
              {this.renderSlides()}
          </div>
          <div className="slide__wrapper mobile">
          <CSSTransitionGroup
            transitionName={swipeLeft ? 'left-swipe' : 'right-swipe'}
            transitionEnterTimeout={200}
            transitionLeaveTimeout={200}
          >
              {this.renderMobileSlide()}
          </CSSTransitionGroup>
          </div>
          <div className="dots desktop">
            {this.renderDots()}
          </div>
        </div>
        <div ref={this.videoWrapper} className="video-carousel__content">
          <img className="iphone__frame" src={iPhoneFrame} alt="iphone frame" />
          <img className="iphone__shadow" src={iPhoneShadow} alt="iphone shadow" />
          <div className="video__wrapper">
            <div className="video__overlay" ref={this.overlay}></div>
            { this.detectIE() 
                ? <img src={slides[selected].fallback} />
                : <video src={slides[selected].video} muted ref={this.video} playsInline autoPlay />
            }
          </div>
          <Arrow className="arrow"/>
        </div>
        <div className="dots mobile">
          {this.renderDots()}
        </div>
      </div>
    );
  }
}