import React from "react";
import "./Download.css";
import { ReactComponent as Apple } from "../../../static/imgs/download/download_apple.svg";
import { ReactComponent as Google } from "../../../static/imgs/download/download_google.svg";

export default class Download extends React.Component {
	state: {
		platform: string,
		appleUrl: string,
		googleUrl: string
	};

	constructor(props : any) {
		super(props);
		this.state = {
			platform: "",
			appleUrl: "/",
			googleUrl: "/"
		}
	}

	componentDidMount() {
		this.setState({
			platform: this.getPlatform()
		});
	}
	

	getPlatform () {
		var userAgent = navigator.userAgent || navigator.vendor;

		if (/android/i.test(userAgent)) {
			return "Android";
		}

		if (/iPad|iPhone|iPod/.test(userAgent)) {
			return "iOS";
		}
	}

	render() {
		let { platform, googleUrl, appleUrl } = this.state;

		return (
			<div className="download__wrapper">
				{platform !== "Android" && (<a href={appleUrl}><Apple className="store-badge" /></a>)}
				{platform !== "iOS" && (<a href={googleUrl}><Google className="store-badge" /></a>)}
			</div>
		);
	}
}