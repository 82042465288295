import React from "react";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
// import { useRoutes } from "hookrouter"; // TODO: figure out issues with IE 11 and Edge
// import { routes } from "./constants/routes";
// import { NotFoundPage } from "./components/NotFound";
import { LandingPage } from "./components/Landing";

const App = () => {
  return (
    <div>
      <Navigation />
      {/* {useRoutes(routes) || <NotFoundPage />} */}
      <LandingPage />
      <Footer />
    </div>
  );
};

export default App;
