import React from "react";
import "./WhereItHelps.css";
import { ReactComponent as Arrow } from "../../../../static/imgs/arrows/right_01.svg";
import iPhoneFrame from "../../../../static/imgs/iphone_frame.png";
import iPhoneShadow from "../../../../static/imgs/shadow/shadow_flat.svg";
import iPhoneContent from "../../../../static/imgs/woodworking@2x.jpg";


export default class WhereItHelps extends React.Component {
  private container: React.RefObject<HTMLDivElement>;
  private iphone: React.RefObject<HTMLDivElement>;
  private seen: boolean;

  constructor(props: any) {
    super(props);
    this.container = React.createRef();
    this.iphone = React.createRef();
    this.seen = false;
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll.bind(this));
  }

  onScroll () {
    if (this.container.current && window.scrollY >= this.container.current.offsetTop - (this.container.current.offsetHeight * 0.5)) {
      if (!this.seen) {
        this.seen = true;
        if (this.iphone.current)
          this.iphone.current.classList.add("right-entry");
      }
    }
  }
  
  render () {
    return (
      <section className="where-it-helps" ref={this.container}>
        <div className="content">
          <h2>Where it HLPS</h2>
          <p className="description">
            We can help unleash those tied up sponsorship dollars for tangible results. We help brands improve their visibility with festival attendees through focused, relevant in-app advertising. 
          </p>
          <p className="quote desktop">
            “Until fairs and festivals fully develop their digital capabilities sponsorship spending will likely remain sluggish for the foreseeable future.” <span className="bold">IEG/sponsorship.com</span>
          </p>
        </div>
        <div className="phone" ref={this.iphone}>
          <img className="iphone-frame" src={iPhoneFrame} alt="iphone frame" />
          <img className="iphone-shadow" src={iPhoneShadow} alt="iphone shadow" />
          <img className="iphone-content" src={iPhoneContent} alt="Wood working event" />
          <Arrow className="arrow"/>
        </div>
        <p className="quote mobile">
          “Until fairs and festivals fully develop their digital capabilities sponsorship spending will likely remain sluggish for the foreseeable future.” <span className="bold">IEG/sponsorship.com</span>
        </p>
      </section>
    )
  }
}