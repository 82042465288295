import React, { useContext } from "react";
import * as firebase from "firebase";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
};

export const firebaseInstance = firebase.initializeApp(config);
export const FirebaseContext = React.createContext(firebaseInstance);

export const useFirebase = () => {
  return useContext(FirebaseContext);
};
