import React from "react";
import { ReactComponent as Facebook } from "../../static/imgs/icons/social/facebook.svg";
import { ReactComponent as Instagram } from "../../static/imgs/icons/social/instagram.svg";
import { ReactComponent as Linkedin } from "../../static/imgs/icons/social/linkedin.svg";
import { ReactComponent as Twitter } from "../../static/imgs/icons/social/twitter.svg";
import "./Footer.css";

export default class Footer extends React.Component  {
  render() {
    return (
      <div className="footer">
        <div className="social">
          <a href="https://www.facebook.com/FestFrwrd/" target="_blank" rel="noopener noreferrer">
            <Facebook className="social-icon" />
          </a>
          <a href="https://www.instagram.com/FestFrwrd/" target="_blank" rel="noopener noreferrer">
            <Instagram className="social-icon" />
          </a>
          <a href="https://www.linkedin.com/company/festfrwrd/" target="_blank" rel="noopener noreferrer">
            <Linkedin className="social-icon" />
          </a>
          <a href="https://twitter.com/FestFrwrd" target="_blank" rel="noopener noreferrer">
            <Twitter className="social-icon" />
          </a>
        </div>
        <div className="copy">
          <span>© 2019 FESTFORWARD, LLC.</span>
          <span className="divider">|</span>
          <span><a href="mailto:contact@festfrwrd.com">contact@festfrwrd.com</a></span>
          <span className="divider">|</span>
          <span>404.555.1234</span>
          <span className="divider">|</span>
          <span>PRIVACY POLICY</span>
        </div>
      </div>
    );
  }
}