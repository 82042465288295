import React from "react";
import Download from "../Download";
import "./Hero.css";
import heroImage from "../../../static/imgs/hero.jpg";
import mobilePhone from "../../../static/imgs/hero_iphone_small@2x.png";
import mobileShadow from "../../../static/imgs/shadow/shadow_diagonal.svg";
import desktopPhone from "../../../static/imgs/hero_iphone_large@2x.png";
import desktopShadow from "../../../static/imgs/shadow/shadow-persp.svg";
import desktopArrow from "../../../static/imgs/arrows/right_02.svg";

interface HeroProps {
  appLaunched: boolean
}

export default class Hero extends React.Component<HeroProps> {
  scrollToEmail () {
    let emailForm = document.getElementById("mail-chimp-container");

    if (checkForEdgeOrIE()) {
      window.location.href = window.location.origin + "#subscribe";
    } else if (emailForm) {
      window.scrollTo({
        behavior: 'smooth',
        top: emailForm.offsetTop,
      });
    }

    function checkForEdgeOrIE () {
      if (/MSIE 10/i.test(navigator.userAgent)) {
        return true;
      }
     
      if (/MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent)) {
        return true;
      }
     
      if (/Edge\/\d./i.test(navigator.userAgent)){
        return true;
      }
  
      return false;
    }
  }

  render() {
    let { appLaunched } = this.props;

    return (
      <div className="hero">
        <div className="hero-wrapper">
          <img className="hero-image" src={heroImage} alt="festival" />
        </div>
        <div className="hero-overlay"></div>
        <div className="hero-content">
          <div className="copy-wrapper">
            <h1>Welcome to the <span className="highlight">FUTR of FSTIVLS<span className="darken">.</span></span></h1>
            <div className="desktop">
              {appLaunched && (<Download />)}
              {!appLaunched && (
                <div className="coming-soon">
                  <p>Coming Soon.<span className="notify-me" onClick={this.scrollToEmail}>Notify Me!</span></p>
                  <a id="form-anchor" href="#mail-chimp-container" style={{ position: "absolute", visibility: "hidden"}}></a>
                </div>
              )}
            </div>
          </div>
          <div className="phone-wrapper desktop">
            <img className="hero-phone__shadow" src={desktopShadow} alt="phone shadow" />
            <img className="hero-phone__arrow" src={desktopArrow} alt="arrow" />
            <img className="hero-phone" src={desktopPhone} alt="phone" />
          </div>
          <div className="phone-wrapper mobile">
            <img className="hero-phone__shadow" src={mobileShadow} alt="phone shadow" />
            <img className="hero-phone" src={mobilePhone} alt="phone" />
          </div>
          <div className="mobile-message mobile">
            {appLaunched && (<Download />)}
            {!appLaunched && (
              <div className="coming-soon">
                <p>Coming Soon.<span className="notify-me" onClick={this.scrollToEmail}>Notify Me!</span></p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}