import React from "react";
import Hero from "./Hero";
import Download from "./Download";
import WhatItDoes from "./Sections/WhatItDoes";
import VideoCarousel from "./VideoCarousel";
import WhereItHelps from "./Sections/WhereItHelps";
import WhyItsDifferent from "./Sections/WhyItsDifferent";
import MailChimpForm from "../MailChimpForm";
import { ReactComponent as GPS } from "../../static/imgs/illustrations/GPS.svg";
import { ReactComponent as DTLS } from "../../static/imgs/illustrations/DTLS.svg";
import { ReactComponent as HAPNIN } from "../../static/imgs/illustrations/HAPNIN.svg";
import GPSvid from "../../static/videos/GPS.mp4";
import VendorVid from "../../static/videos/Vendor.mp4";
import ScheduleVid from "../../static/videos/Schedule.mp4";
import GPSFallback from "../../static/videos/fallback/GPS.png";
import VendorFallback from "../../static/videos/fallback/Vendor.png";
import ScheduleFallback from "../../static/videos/fallback/Schedule.png";
import "./Landing.css"

const appLaunched = false; // TODO: When apps have launched, set to true
const slides = [
  {
    img: <GPS className="icon" />,
    title: "GPS Your Fest",
    description: "Use enhanced wayfinding to navigate the festival grounds.",
    video: GPSvid,
    fallback: GPSFallback
  },
  {
    img: <DTLS className="icon" />,
    title: "Find Vendor Deets",
    description: "Know all about participating vendors before you even get there!",
    video: VendorVid,
    fallback: VendorFallback
  },
  {
    img: <HAPNIN className="icon" />,
    title: "See What’s Happenin’",
    description: "No more FOMO! All the scheduled events at your fingertips.",
    video: ScheduleVid,
    fallback: ScheduleFallback
  },
];


export const LandingPage = () => {
  return (
    <div className="mainContainer">
      <Hero appLaunched={appLaunched} />
      <div className="page-content">
        <WhatItDoes />
        <VideoCarousel slides={slides} title="How it WRKS" />
        <WhereItHelps />
        <WhyItsDifferent />
        {appLaunched && (
          <section>
            <h2>DWNLD Now!</h2>
            <p className="description-copy">Be in the know before you go and never miss your favorites.</p>
            <Download />
          </section>
        )}
      </div>
      <MailChimpForm />
    </div>
  );
};
