import React from "react";
import "./Navigation.css";
import { ReactComponent as Logo } from "../../static/imgs/FF_logo.svg";
import { ReactComponent as MailIcon } from "../../static/imgs/icons/mail.svg";

export default class Navigation extends React.Component {
  render() {
    return (
      <div className="nav-wrapper">
        <div className="branding">
          <Logo className="logo" />
        </div>
        <a className="contact" href="mailto:contact@festfrwrd.com">
          <span>Contact us</span>
          <MailIcon className="mail-icon" />
        </a>
      </div>
    );
  }
}
