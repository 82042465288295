import React from "react";
import { ReactComponent as Arrow } from "../../../../static/imgs/arrows/down_01.svg"

export default () => {
  return (
    <section>
      <h2>What it DOS</h2>
      <p className="description-copy bold">FestFRWRD delivers the <span className="highlight">ultimate in-festival experience</span> for attendees while simultaneously increasing sponsorship value for festivals around the world and right next door.</p>
      <Arrow className="arrow-1"/>
    </section>
  );
};
