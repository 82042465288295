import React from "react";
import "./WhyItsDifferent.css";
import { ReactComponent as User } from "../../../../static/imgs/illustrations/User.svg"
import { ReactComponent as Insights } from "../../../../static/imgs/illustrations/Insights.svg"
import { ReactComponent as Money } from "../../../../static/imgs/illustrations/Money.svg"
import { ReactComponent as Arrow } from "../../../../static/imgs/arrows/down_01.svg"

export default class WhyItsDifferent extends React.Component {
  private container: React.RefObject<HTMLDivElement>;
  private seen: boolean;

  constructor(props: any) {
    super(props);
    this.container = React.createRef();
    this.seen = false;
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll.bind(this));
  }

  onScroll () {
    if (this.container.current && this.container.current.offsetTop > 0 && window.scrollY >= this.container.current.offsetTop - (this.container.current.offsetHeight)) {
      if (!this.seen) {
        this.seen = true;
        this.container.current.classList.add("entry");
      }
    }
  }

  render() {
    return (
      <section>
        <div className="why-its-different" ref={this.container}>
          <h2>Why it's DFFRNT</h2>
          <p className="description-copy">We dive deep and tackle the data you need from all angles and provide key data for vendors, festival owners, and sponsors to prove the value of their investment.</p>
          <div className="icon-container">
            <div className="icon-wrapper">
              <User className="icon" />
              <p>Collect Data</p>
            </div>
            <div className="icon-wrapper">
              <Insights className="icon" />
              <p>Apply Insights</p>
            </div>
            <div className="icon-wrapper">
              <Money className="icon" />
              <p>Secure Sponsors</p>
            </div>
          </div>
          <h3 className="quote">“Only 23% of event managers say they can calculate ROI for their events." <span className="author">Harvard Business Review</span></h3>
          <Arrow className="arrow-1"/>
        </div>
      </section>
    )
  }
}